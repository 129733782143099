import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { BarChart, PieChart } from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';

import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Sell as SellIcon,
  ShoppingBag as ShoppingBagIcon,
  NotificationsActive as NotificationsActiveIcon,
  ReceiptLong as ReceiptLongIcon,
  Category,
  Construction,
  Layers,
} from '@mui/icons-material';

import { useAuth } from 'contexts/AuthContext';
import {
  CalendarFormat,
  CurrentDateTimeUnix,
  CurrentMonthUnix,
  DateFormat,
  DateTimeFormat,
} from 'utils/EpochConverter';
import formatCurrency from 'utils/CurrencyFormatter';
import {
  AccountModel,
  getAccounts,
  ReminderModel,
  getReminders,
  TransactionModel,
  getTransactions,
  ProjectModel,
  getProjects,
} from 'apis';

const Dashboard = () => {
  const { branch } = useAuth();
  const theme = useTheme();

  const startDate = CurrentMonthUnix();
  const endDate = CurrentDateTimeUnix();

  const [accountsList, setAccountsList] = useState<AccountModel[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectModel[]>([]);
  const [transactionsList, setTransactionsList] = useState<TransactionModel[]>(
    [],
  );
  const [remindersList, setRemindersList] = useState<ReminderModel[]>([]);

  const [dateList, setDateList] = useState<string[]>([]);
  const [creditAmountData, setCreditAmountData] = useState<number[]>([]);
  const [debitAmountData, setDebitAmountData] = useState<number[]>([]);

  useEffect(() => {
    Promise.all([
      getAccounts({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,balance',
        filter: 'createdAt:onOrAfter:' + startDate,
      }),
      getProjects({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name,status,totalBudget,totalExpense,startDate,endDate',
        filter: 'createdAt:onOrAfter:' + startDate,
      }),
      getTransactions({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,transactionType,category,amount,createdAt',
        filter: 'createdAt:onOrAfter:' + startDate,
      }),
      getReminders({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name,time,notes',
        sort: 'time:asc',
      }),
    ]).then(results => {
      setAccountsList(results[0].rows);
      setProjectsList(results[1].rows);
      setTransactionsList(results[2].rows);
      setRemindersList(results[3].rows);
    });
  }, []);

  useEffect(() => {
    const dates: string[] = [];
    const creditData: number[] = [];
    const debitData: number[] = [];
    const dateStarts = _.range(startDate, endDate, 86400);
    _.map(dateStarts, (value: number) => {
      const creditTotal = _.sumBy(
        _.filter(
          transactionsList,
          item =>
            item.transactionType == 'Credit' &&
            item.createdAt >= value &&
            item.createdAt < value + 86400,
        ),
        item => Number(item.amount),
      );
      const debitTotal = _.sumBy(
        _.filter(
          transactionsList,
          item =>
            item.transactionType == 'Debit' &&
            item.createdAt >= value &&
            item.createdAt < value + 86400,
        ),
        item => Number(item.amount),
      );
      dates.push(DateFormat(value));
      creditData.push(creditTotal);
      debitData.push(debitTotal);
    });
    setDateList(dates);
    setCreditAmountData(creditData);
    setDebitAmountData(debitData);
  }, [transactionsList]);

  return (
    <>
      <Helmet>
        <title>SKC - Dashboard</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ borderRadius: 4, p: 2 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" pb={0} fontWeight={700}>
                  Total Balance
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <AccountBalanceWalletIcon fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  {'As of ' + DateTimeFormat(endDate)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={700}>
                  {formatCurrency(
                    _.sumBy(accountsList, item => Number(item.balance)),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ borderRadius: 4, p: 2 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" pb={0} fontWeight={700}>
                  Total Credit
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <SellIcon fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  {DateFormat(startDate) + ' - Now'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={700}>
                  {formatCurrency(
                    _.sumBy(
                      _.filter(
                        transactionsList,
                        item => item.transactionType === 'Credit',
                      ),
                      item => Number(item.amount),
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ borderRadius: 4, p: 2 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" pb={0} fontWeight={700}>
                  Total Debit
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <ShoppingBagIcon fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  {DateFormat(startDate) + ' - Now'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={700}>
                  {formatCurrency(
                    _.sumBy(
                      _.filter(
                        transactionsList,
                        item => item.transactionType === 'Debit',
                      ),
                      item => Number(item.amount),
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card sx={{ borderRadius: 4, p: 2 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" pb={0} fontWeight={700}>
                  Projects
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <Construction fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  Active
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="center">
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  Paused
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle1" pb={1} color="secondary.light">
                  Complete
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" fontWeight={700}>
                  {_.size(
                    _.filter(
                      projectsList,
                      item =>
                        !['Work Paused', 'Completed'].includes(item.status),
                    ),
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="center">
                <Typography variant="h6" fontWeight={700}>
                  {_.size(
                    _.filter(projectsList, item =>
                      ['Work Paused'].includes(item.status),
                    ),
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="h6" fontWeight={700}>
                  {_.size(
                    _.filter(projectsList, item =>
                      ['Completed'].includes(item.status),
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ borderRadius: 4, p: 3, pb: 1 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" fontWeight={700}>
                  Transactions
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <ReceiptLongIcon fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={12} height={400}>
                <BarChart
                  series={[
                    {
                      data: creditAmountData,
                      label: 'Credit',
                      id: 'crId',
                      stack: 'total',
                      color: 'primary.main',
                    },
                    {
                      data: debitAmountData,
                      label: 'Debit',
                      id: 'dbId',
                      stack: 'total',
                      color: theme.palette.secondary.light,
                    },
                  ]}
                  xAxis={[
                    {
                      data: dateList,
                      scaleType: 'band',
                    },
                  ]}
                  borderRadius={16}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ borderRadius: 4, p: 3, pb: 1 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" fontWeight={700}>
                  Spend Category
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <Category fontSize="large" />
                </Icon>
              </Grid>
              <Grid item xs={12} height={400}>
                <PieChart
                  colors={[
                    'black',
                    'grey',
                    'wheat',
                    'tan',
                    'lightblue',
                    'lightcoral',
                    'cornflowerblue',
                    'crimson',
                    'darkolivegreen',
                    'gold',
                    'olivedrab',
                    'orange',
                  ]}
                  series={[
                    {
                      paddingAngle: 1,
                      innerRadius: '60%',
                      outerRadius: '75%',
                      data: _.map(
                        _.groupBy(transactionsList, 'category'),
                        (items, category) => ({
                          label: category,
                          value: _.sumBy(items, item => Number(item.amount)),
                        }),
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ borderRadius: 4, p: 3, height: 400 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" fontWeight={700}>
                  Work Progress
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <Layers fontSize="large" />
                </Icon>
              </Grid>
            </Grid>
            <Grid
              container
              overflow="scroll"
              maxHeight="-webkit-fill-available">
              {_.map(projectsList, project => {
                const budget = Math.round(
                  (project.totalExpense / project.totalBudget) * 100,
                );
                let time = Math.round(
                  ((CurrentDateTimeUnix() - project.startDate) /
                    (project.endDate - project.startDate)) *
                    100,
                );
                time = time < 0 ? 0 : time;
                time = time > 100 ? 100 : time;
                return (
                  <Grid container key={project.id}>
                    <Grid item xs={8} sm={10} pt={1}>
                      <Typography variant="subtitle1">
                        {project.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="0.8rem"
                        color={theme.palette.secondary.light}>
                        {project.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} pt={1} textAlign="right">
                      <Box
                        sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                          variant="determinate"
                          color="primary"
                          value={budget}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Typography variant="caption" component="div">
                            {budget + '%'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} sm={1} pt={1} textAlign="right">
                      <Box
                        sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                          variant="determinate"
                          color="primary"
                          value={time}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Typography variant="caption" component="div">
                            {time + '%'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ borderRadius: 4, p: 3, height: 400 }}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h5" fontWeight={700}>
                  Upcoming Reminders
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Icon fontSize="large">
                  <NotificationsActiveIcon fontSize="large" />
                </Icon>
              </Grid>
            </Grid>
            <Grid
              container
              overflow="scroll"
              maxHeight="-webkit-fill-available">
              {_.map(remindersList, reminder => {
                return (
                  <Grid container key={reminder.id}>
                    <Grid item xs={8} pt={1}>
                      <Typography variant="subtitle1">
                        {reminder.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="0.8rem"
                        color={theme.palette.secondary.light}>
                        {reminder.notes}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} pt={1} textAlign="right">
                      <Typography fontWeight={700}>
                        {DateTimeFormat(reminder.time)}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="0.8rem"
                        color={theme.palette.secondary.light}>
                        {CalendarFormat(reminder.time)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(Dashboard);
