import React from 'react';

import { Alert, Slide, Snackbar } from '@mui/material';

export type AlertType = 'error' | 'info' | 'success' | 'warning';

interface AlertPopupType {
  open: boolean;
  type: AlertType;
  message: string;
  autoHideDuration?: number;
  onClose: () => void;
}

const AlertPopup = (props: AlertPopupType) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={props.autoHideDuration || null}
      open={props.open}
      TransitionComponent={props => <Slide {...props} direction="left" />}
      onClose={props.onClose}>
      <Alert severity={props.type} variant="filled" sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default React.memo(AlertPopup);
