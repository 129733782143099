export const EmployeeFormStatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'InActive', label: 'InActive' },
];

export const EmployeeFormPositionOptions = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Accountant', label: 'Accountant' },
  { value: 'Driver', label: 'Driver' },
  { value: 'Supervisor', label: 'Supervisor' },
  { value: 'Labour', label: 'Labour' },
];

export const ProjectFormStatusOptions = [
  { value: 'Initiated', label: 'Initiated' },
  { value: 'Tender Created', label: 'Tender Created' },
  { value: 'Tender Submitted', label: 'Tender Submitted' },
  { value: 'Tender Approved', label: 'Tender Approved' },
  { value: 'Tender Rejected', label: 'Tender Rejected' },
  { value: 'Payment Received', label: 'Payment Received' },
  { value: 'Work Started', label: 'Work Started' },
  { value: 'Work Paused', label: 'Work Paused' },
  { value: 'Work Completed', label: 'Work Completed' },
  { value: 'Payment Pending', label: 'Payment Pending' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Closed', label: 'Closed' },
];

export const ReminderFormStatusOptions = [
  { value: 'Enabled', label: 'Enabled' },
  { value: 'Disabled', label: 'Disabled' },
];

export const TransactionFormStatusOptions = [
  { value: 'Completed', label: 'Completed' },
  { value: 'Pending', label: 'Pending' },
];

export const TransactionFormTypeOptions = [
  { value: 'Credit', label: 'Credit' },
  { value: 'Debit', label: 'Debit' },
];

export const TransactionFormCategoryOptions = [
  { value: 'Material Purchase', label: 'Material Purchase' },
  { value: 'Material Sale', label: 'Material Sale' },
  { value: 'Vehicle Rent', label: 'Vehicle Rent' },
  { value: 'Vehicle Maintenance', label: 'Vehicle Maintenance' },
  { value: 'Vehicle Insurance', label: 'Vehicle Insurance' },
  { value: 'Vehicle EMI', label: 'Vehicle EMI' },
  { value: 'Vehicle TAX', label: 'Vehicle TAX' },
  { value: 'Diesel', label: 'Diesel' },
  { value: 'Petrol', label: 'Petrol' },
  { value: 'Salary', label: 'Salary' },
  { value: 'Labour', label: 'Labour' },
  { value: 'Office Expense', label: 'Office Expense' },
  { value: 'Water', label: 'Water' },
  { value: 'Food', label: 'Food' },
  { value: 'Electricity', label: 'Electricity' },
  { value: 'Project Settlement', label: 'Project Settlement' },
  { value: 'Supplier Settlement', label: 'Supplier Settlement' },
  { value: 'EMI Payment', label: 'EMI Payment' },
  { value: 'Purchase', label: 'Purchase' },
  { value: 'Sale', label: 'Sale' },
  { value: 'Donation', label: 'Donation' },
  { value: 'Office Credit', label: 'Office Credit' },
  { value: 'Office Debit', label: 'Office Debit' },
  { value: 'Bank Charge', label: 'Bank Charge' },
  { value: 'Donation', label: 'Donation' },
  { value: 'Other', label: 'Other' },
];

export const TimeSheetFormTypeOptions = [
  { value: 'Hours', label: 'Hours' },
  { value: 'Distance', label: 'Distance' },
  { value: 'Volume', label: 'Volume' },
];

export const TimeSheetFormStatusOptions = [
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Cancelled', label: 'Cancelled' },
];

export const TimeSheetFormHourOptions = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' },
];

export const OperationFormTypeOptions = [
  { value: 'Inward', label: 'Inward' },
  { value: 'Outward', label: 'Outward' },
];

export const WorkLogFormStatusOptions = [
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Cancelled', label: 'Cancelled' },
];

export const WorkLogFormTypeOptions = [
  { value: 'Labour', label: 'Labour' },
  { value: 'Material', label: 'Material' },
  { value: 'Vehicle', label: 'Vehicle' },
];

export const WorkLogFormUnitOptions = [
  { value: 'Hour', label: 'Hour' },
  { value: 'Day', label: 'Day' },
  { value: 'Km', label: 'Km' },
  { value: 'Unit', label: 'Unit' },
  { value: 'Ton', label: 'Ton' },
  { value: 'Liter', label: 'Liter' },
];
