import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import {
  CurrencyRupee as TransactionIcon,
  NotificationsActive as ReminderIcon,
  Today as WorkLogIcon,
  AttachFile as AttachFileIcon,
} from '@mui/icons-material';

import {
  CustomerModel,
  getProjectById,
  getReminders,
  getTransactions,
  ProjectModel,
  ReminderModel,
  TransactionModel,
} from 'apis';
import { DateFormat, DateTimeFormat } from 'utils/EpochConverter';
import formatCurrency from 'utils/CurrencyFormatter';
import { useAuth } from 'contexts/AuthContext';
import { useLoader } from 'contexts/LoaderContext';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  TransactionFormCategoryOptions,
  TransactionFormStatusOptions,
  TransactionFormTypeOptions,
} from 'constants/DropDownOptions';
import { Copyright } from 'components/atoms';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Tab panel component
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      id={`simple-tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

const ProjectDetail = () => {
  const projectId = useParams().id;
  const { branch } = useAuth();

  const { loading, showLoader, hideLoader } = useLoader();
  const [projectDetail, setProjectDetail] = useState<ProjectModel>();

  const [transactionsList, setTransactionsList] = useState<TransactionModel[]>(
    [],
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [transactionRowCount, setTransactionRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');

  const [remindersList, setRemindersList] = useState<ReminderModel[]>([]);
  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (projectId) {
      showLoader();
      Promise.all([
        getProjectById(projectId),
        getTransactions({
          branchId: branch?.id,
          pageSize: 10000,
          // fields: 'id,transactionType,category,amount,createdAt',
          filter: `projectId:is:${projectId}`,
        }),
        getReminders({
          branchId: branch?.id,
          pageSize: 10000,
          fields: 'id,name,time,notes',
          sort: 'time:asc',
          filter: `projectId:is:${projectId}`,
        }),
        // getCustomers({
        //   branchId: branch?.id,
        //   pageSize: 10000,
        //   fields: 'id,name',
        //   // filter: `projectId:is:${projectId}`,
        // }),
      ])
        .then(results => {
          console.log(results);

          setProjectDetail(results[0]);
          setTransactionsList(results[1].rows);
          setTransactionRowCount(results[1].total);
          setRemindersList(results[2].rows);
          // setCustomersList(results[3].rows);
        })
        .catch(error => console.error(error))
        .finally(() => hideLoader());
    }
  }, []);

  const fetchData = () => {
    showLoader();
    getTransactions({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: `projectId:is:${projectId}`,
    })
      .then(response => {
        setTransactionsList(response.rows);
        setTransactionRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Projects: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortColumn, sortOrder]);

  const columns: GridColDef[] = [
    // {
    //   field: 'accountId',
    //   headerName: 'ACCOUNT',
    //   flex: 0.5,
    //   type: 'singleSelect',
    //   valueOptions: _.map(accountsList, record => {
    //     return record.id;
    //   }),
    //   getOptionLabel: value => {
    //     const result = _.find(accountsList, record => {
    //       return record.id == value;
    //     });
    //     return result ? result.name : '...';
    //   },
    // },
    {
      field: 'createdAt',
      headerName: 'DATE',
      flex: 0.8,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateTimeFormat(params.value / 1000)}</span>;
      },
    },
    // {
    //   field: 'projectId',
    //   headerName: 'PROJECT',
    //   flex: 0.8,
    //   type: 'singleSelect',
    //   valueOptions: _.map(projectsList, record => {
    //     return record.id;
    //   }),
    //   getOptionLabel: value => {
    //     const result = _.find(projectsList, record => {
    //       return record.id == value;
    //     });
    //     return result ? result.name : '...';
    //   },
    // },
    {
      field: 'category',
      headerName: 'CATEGORY',
      flex: 0.8,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormCategoryOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormCategoryOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionType',
      headerName: 'TYPE',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormTypeOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormTypeOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionStatus',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>SKC - Project Detail</title>
      </Helmet>

      {projectDetail && (
        <Box sx={{ width: '100%', p: 1 }}>
          <Paper sx={{ mr: 2, p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Project Details - {projectDetail?.viewId}
                </Typography>

                <Divider sx={{ marginBottom: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Name:</strong> {projectDetail?.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Customer: </strong>
                      TODO: bind customer name
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Location:</strong> {projectDetail?.location}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Status:</strong> {projectDetail?.status}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Start Date: </strong>
                      {DateFormat(projectDetail?.startDate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>End Date: </strong>
                      {DateFormat(projectDetail?.endDate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Total Budget: </strong>
                      {formatCurrency(projectDetail?.totalBudget)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Total Expense: </strong>
                      {formatCurrency(projectDetail?.totalExpense)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Notes: </strong>
                      {projectDetail?.notes}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <PieChart
                  colors={[
                    'black',
                    'grey',
                    'wheat',
                    'tan',
                    'lightblue',
                    'lightcoral',
                    'cornflowerblue',
                    'crimson',
                    'darkolivegreen',
                    'gold',
                    'olivedrab',
                    'orange',
                  ]}
                  series={[
                    {
                      paddingAngle: 1,
                      innerRadius: '60%',
                      outerRadius: '75%',
                      data: _.map(
                        _.groupBy(transactionsList, 'category'),
                        (items, category) => ({
                          label: category,
                          value: _.sumBy(items, item => Number(item.amount)),
                        }),
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Tabs */}
          <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                label="Transactions"
                icon={<TransactionIcon />}
                iconPosition="end"
              />
              <Tab
                label="Reminders"
                icon={<ReminderIcon />}
                iconPosition="end"
              />
              <Tab
                label="Worklogs"
                icon={<WorkLogIcon />}
                iconPosition="end"
                disabled
              />
              <Tab
                label="Attachments"
                icon={<AttachFileIcon />}
                iconPosition="end"
              />
            </Tabs>
          </Box>

          {/* Tab Content */}
          <TabPanel value={tabValue} index={0}>
            <Paper sx={{ height: '50vh', minHeight: 400, borderRadius: 4 }}>
              <DataGrid
                loading={loading}
                rows={transactionsList}
                columns={columns}
                pagination
                paginationMode="server"
                rowCount={transactionRowCount}
                pageSizeOptions={pageSizeOptions}
                onPaginationModelChange={model => {
                  setPage(model.page);
                  setPageSize(model.pageSize);
                }}
                sortingMode="server"
                onSortModelChange={model => {
                  _.map(model, sortItem => {
                    setSortColumn(sortItem.field);
                    setSortOrder(sortItem.sort);
                  });
                }}
                disableRowSelectionOnClick
                sx={{ px: 2, pt: 2, borderRadius: 4 }}
              />
            </Paper>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Typography>Content for Reminders</Typography>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Typography>Content for Worklogs</Typography>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Typography>Content for Attachments</Typography>
          </TabPanel>
          <Copyright />
        </Box>
      )}
    </>
  );
};

export default React.memo(ProjectDetail);
