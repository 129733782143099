import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createAccount,
  deleteAccount,
  AccountModel,
  getAccounts,
  updateAccount,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import AccountForm from './AccountForm';
import formatCurrency from 'utils/CurrencyFormatter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import { LocaleText, TableStyle } from 'constants/TableConstants';

export type FormModeType = 'create' | 'edit' | 'delete';

const Accounts = () => {
  const { branch } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedAccountID, setSelectedAccountID] = useState<GridRowId>();
  const [accountsData, setAccountsData] = useState<AccountModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const fetchData = () => {
    showLoader();
    getAccounts({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setAccountsData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Accounts: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddAccount = (newAccount: Partial<AccountModel>) => {
    showLoader();
    createAccount(newAccount as Omit<AccountModel, 'id'>)
      .then(response => {
        setAccountsData(prevAccounts => [...prevAccounts, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding account: ', error))
      .finally(() => hideLoader());
  };

  const handleEditAccount = (editedAccount: Partial<AccountModel>) => {
    showLoader();
    if (selectedAccountID) {
      updateAccount(selectedAccountID, editedAccount)
        .then(response => {
          setAccountsData(
            _.chain(accountsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(accounts => {
                const index = _.findIndex(
                  accounts,
                  acc => acc.id === selectedAccountID,
                );
                if (index !== -1) accounts[index] = response;
                return accounts;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing account: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteAccount = () => {
    if (selectedAccountID) {
      showLoader();
      deleteAccount(selectedAccountID)
        .then(() => {
          setAccountsData(
            _.reject(accountsData, {
              id: selectedAccountID,
            }) as AccountModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting account: ', error))
        .finally(() => hideLoader());
    }
  };

  const openAccountForm = () => {
    let title: string;
    let currentAccount: Partial<AccountModel> = {
      branchId: branch?.id,
      name: '',
      code: '',
      balance: 0,
      minimumBalance: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      details: '',
      notes: '',
    };
    let callback: (account: Partial<AccountModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Account';
        callback = handleAddAccount;
        break;
      case 'edit':
        title = 'Edit Account';
        callback = handleEditAccount;
        currentAccount = _.chain(accountsData)
          .find(transaction => transaction.id === selectedAccountID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Account';
        callback = handleDeleteAccount;
        currentAccount = _.chain(accountsData)
          .find(transaction => transaction.id === selectedAccountID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Account';
        callback = handleAddAccount;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <AccountForm
            mode={formMode}
            initFormState={currentAccount}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    { field: 'code', headerName: 'CODE', flex: 0.4, type: 'string' },
    {
      field: 'balance',
      headerName: 'BALANCE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'minimumBalance',
      headerName: 'MINIMUM BALANCE',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'cGst',
      headerName: 'CGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'sGst',
      headerName: 'SGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'iGst',
      headerName: 'IGST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedAccountID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedAccountID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>SKC - Accounts</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={accountsData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          localeText={LocaleText}
          sx={TableStyle}
        />
      </Paper>

      {openForm && openAccountForm()}
    </>
  );
};

export default React.memo(Accounts);
