import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createEmployee,
  deleteEmployee,
  EmployeeModel,
  getEmployees,
  updateEmployee,
} from 'apis/EmployeeApi';
import { useLoader } from 'contexts/LoaderContext';
import EmployeeForm from './EmployeeForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  EmployeeFormPositionOptions,
  EmployeeFormStatusOptions,
} from 'constants/DropDownOptions';
import {
  CurrentDateUnix,
  DateFormat,
  ParseEpochDateString,
} from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';
import { LocaleText, TableStyle } from 'constants/TableConstants';

export type FormModeType = 'create' | 'edit' | 'delete';

const Employees = () => {
  const { branch } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedEmployeeID, setSelectedEmployeeID] = useState<GridRowId>();
  const [employeesData, setEmployeesData] = useState<EmployeeModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const fetchData = () => {
    showLoader();
    getEmployees({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setEmployeesData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Employees: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddEmployee = (newEmployee: Partial<EmployeeModel>) => {
    showLoader();
    createEmployee(newEmployee as Omit<EmployeeModel, 'id'>)
      .then(response => {
        setEmployeesData(prevEmployees => [...prevEmployees, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding employee: ', error))
      .finally(() => hideLoader());
  };

  const handleEditEmployee = (editedEmployee: Partial<EmployeeModel>) => {
    showLoader();
    if (selectedEmployeeID) {
      updateEmployee(selectedEmployeeID, editedEmployee)
        .then(response => {
          setEmployeesData(
            _.chain(employeesData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(employees => {
                const index = _.findIndex(
                  employees,
                  emp => emp.id === selectedEmployeeID,
                );
                if (index !== -1) employees[index] = response;
                return employees;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing employee: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteEmployee = () => {
    if (selectedEmployeeID) {
      showLoader();
      deleteEmployee(selectedEmployeeID)
        .then(() => {
          setEmployeesData(
            _.reject(employeesData, {
              id: selectedEmployeeID,
            }) as EmployeeModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting employee: ', error))
        .finally(() => hideLoader());
    }
  };

  const openEmployeeForm = () => {
    let title: string;
    let currentEmployee: Partial<EmployeeModel> = {
      branchId: branch?.id,
      name: '',
      email: '',
      password: '',
      aadhaar: '',
      pan: '',
      license: '',
      address: '',
      phone1: '',
      phone2: '',
      phone3: '',
      status: '',
      position: '',
      doj: CurrentDateUnix(),
      permissions: [],
      notes: '',
    };
    let callback: (employee: Partial<EmployeeModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Employee';
        callback = handleAddEmployee;
        break;
      case 'edit':
        title = 'Edit Employee';
        callback = handleEditEmployee;
        currentEmployee = _.chain(employeesData)
          .find(emp => emp.id === selectedEmployeeID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Employee';
        callback = handleDeleteEmployee;
        currentEmployee = _.chain(employeesData)
          .find(emp => emp.id === selectedEmployeeID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Employee';
        callback = handleAddEmployee;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <EmployeeForm
            mode={formMode}
            initFormState={currentEmployee}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      type: 'string',
    },
    { field: 'email', headerName: 'EMAIL', flex: 0.8, type: 'string' },
    { field: 'phone1', headerName: 'PHONE', flex: 0.5, type: 'string' },
    {
      field: 'doj',
      headerName: 'JOINED',
      flex: 0.5,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    { field: 'aadhaar', headerName: 'AADHAAR', flex: 0.5, type: 'string' },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 0.5,
      type: 'singleSelect',
      valueOptions: _.map(EmployeeFormPositionOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(EmployeeFormPositionOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(EmployeeFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(EmployeeFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedEmployeeID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedEmployeeID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>SKC - Employees</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={employeesData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                if (['doj'].includes(filterModel.field)) {
                  setFilterValue(ParseEpochDateString(filterModel.value));
                } else {
                  setFilterValue(filterModel.value);
                }
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          localeText={LocaleText}
          sx={TableStyle}
        />
      </Paper>

      {openForm && openEmployeeForm()}
    </>
  );
};

export default React.memo(Employees);
