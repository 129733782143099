import React, { createContext, useReducer, useContext, ReactNode } from 'react';

import globalReducer, {
  ActionType,
  initialState,
  StateType,
} from 'reducers/globalReducer';

const GlobalContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom Hook
export const useGlobalContext = () => useContext(GlobalContext);
