import React from 'react';

import { Link, Typography } from '@mui/material';

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color={props.color}
      align="center"
      {...props}
      mt={1}>
      {'Copyright © '}
      <Link color="inherit" href="https://habiot.com/">
        habiot.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default React.memo(Copyright);
