import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createTransaction,
  deleteTransaction,
  TransactionModel,
  getTransactions,
  updateTransaction,
  AccountModel,
  EmployeeModel,
  ProjectModel,
  CustomerModel,
  SupplierModel,
  VehicleModel,
  InventoryModel,
  getAccounts,
  getEmployees,
  getProjects,
  getCustomers,
  getSuppliers,
  getVehicles,
  getInventories,
} from 'apis';
import { useLoader } from 'contexts/LoaderContext';
import { useAuth } from 'contexts/AuthContext';
import formatCurrency from 'utils/CurrencyFormatter';
import { DateTimeFormat } from 'utils/EpochConverter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  TransactionFormCategoryOptions,
  TransactionFormStatusOptions,
  TransactionFormTypeOptions,
} from 'constants/DropDownOptions';
import TransactionForm from './TransactionForm';
import { LocaleText, TableStyle } from 'constants/TableConstants';

export type FormModeType = 'create' | 'edit' | 'delete';

const Transactions = () => {
  const { branch, employee } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedTransactionID, setSelectedTransactionID] =
    useState<GridRowId>();
  const [transactionsData, setTransactionsData] = useState<TransactionModel[]>(
    [],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<AccountModel[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeeModel[]>([]);
  const [projectsList, setProjectsList] = useState<ProjectModel[]>([]);
  const [customersList, setCustomersList] = useState<CustomerModel[]>([]);
  const [suppliersList, setSuppliersList] = useState<SupplierModel[]>([]);
  const [vehiclesList, setVehiclesList] = useState<VehicleModel[]>([]);
  const [inventoriesList, setInventoriesList] = useState<InventoryModel[]>([]);

  const fetchData = () => {
    showLoader();
    getTransactions({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setTransactionsData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Projects: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    Promise.all([
      getAccounts({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getEmployees({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getProjects({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getCustomers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getSuppliers({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
      getVehicles({ branchId: branch?.id, pageSize: 10000, fields: 'id,name' }),
      getInventories({
        branchId: branch?.id,
        pageSize: 10000,
        fields: 'id,name',
      }),
    ]).then(results => {
      setAccountsList(results[0].rows);
      setEmployeesList(results[1].rows);
      setProjectsList(results[2].rows);
      setCustomersList(results[3].rows);
      setSuppliersList(results[4].rows);
      setVehiclesList(results[5].rows);
      setInventoriesList(results[6].rows);
    });
  }, []);

  const handleAddTransaction = (newTransaction: Partial<TransactionModel>) => {
    showLoader();
    createTransaction(newTransaction as Omit<TransactionModel, 'id'>)
      .then(response => {
        setTransactionsData(prevTransactions => [
          ...prevTransactions,
          response,
        ]);
        handleClose();
      })
      .catch(error => console.error('Error adding transaction: ', error))
      .finally(() => hideLoader());
  };

  const handleEditTransaction = (
    editedTransaction: Partial<TransactionModel>,
  ) => {
    showLoader();
    if (selectedTransactionID) {
      updateTransaction(selectedTransactionID, editedTransaction)
        .then(response => {
          setTransactionsData(
            _.chain(transactionsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(transactions => {
                const index = _.findIndex(
                  transactions,
                  trans => trans.id === selectedTransactionID,
                );
                if (index !== -1) transactions[index] = response;
                return transactions;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing transaction: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteTransaction = () => {
    if (selectedTransactionID) {
      showLoader();
      deleteTransaction(selectedTransactionID)
        .then(() => {
          setTransactionsData(
            _.reject(transactionsData, {
              id: selectedTransactionID,
            }) as TransactionModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting transaction: ', error))
        .finally(() => hideLoader());
    }
  };

  const openTransactionForm = () => {
    let title: string;
    let currentTransaction: Partial<TransactionModel> = {
      branchId: branch?.id,
      projectId: 0,
      employeeId: employee?.id,
      accountId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      category: '',
      transactionType: '',
      transactionStatus: '',
      gstIn: '',
      amount: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      notes: '',
    };
    let callback: (transaction: Partial<TransactionModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Transaction';
        callback = handleAddTransaction;
        break;
      case 'edit':
        title = 'Edit Transaction';
        callback = handleEditTransaction;
        currentTransaction = _.chain(transactionsData)
          .find(transaction => transaction.id === selectedTransactionID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Transaction';
        callback = handleDeleteTransaction;
        currentTransaction = _.chain(transactionsData)
          .find(transaction => transaction.id === selectedTransactionID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Transaction';
        callback = handleAddTransaction;
    }
    return (
      <Dialog open={openForm} fullScreen={fullScreen} onClose={handleClose}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <TransactionForm
            mode={formMode}
            initFormState={currentTransaction}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'accountId',
      headerName: 'ACCOUNT',
      flex: 0.5,
      type: 'singleSelect',
      valueOptions: _.map(accountsList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(accountsList, record => {
          return record.id == value;
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'createdAt',
      headerName: 'DATE',
      flex: 0.8,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateTimeFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'projectId',
      headerName: 'PROJECT',
      flex: 0.8,
      type: 'singleSelect',
      valueOptions: _.map(projectsList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(projectsList, record => {
          return record.id == value;
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'category',
      headerName: 'CATEGORY',
      flex: 0.8,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormCategoryOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormCategoryOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionType',
      headerName: 'TYPE',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormTypeOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormTypeOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionStatus',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormStatusOptions, 'value'),
      getOptionLabel: value => {
        const result = _.find(TransactionFormStatusOptions, {
          value: value as string,
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && formatCurrency(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.6,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedTransactionID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedTransactionID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>SKC - Transactions</title>
      </Helmet>

      <Paper sx={{ height: '90vh', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={transactionsData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          localeText={LocaleText}
          sx={TableStyle}
        />
      </Paper>

      {openForm && openTransactionForm()}
    </>
  );
};

export default React.memo(Transactions);
