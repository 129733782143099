import { ThemeOptions } from '@mui/material/styles';

import typography from './typography';

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
    },
    secondary: {
      light: '#888',
      main: '#fff',
    },
  },
  typography: typography,
};

export default lightThemeOptions;
