export const LocaleText = {
  noRowsLabel: 'We looked everywhere... no data!',
};

export const TableStyle = {
  px: 2,
  pt: 2,
  borderRadius: 4,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bolder',
  },
};
