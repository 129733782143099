import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedProjectModel {
  rows: ProjectModel[];
  total: number;
}

export interface ProjectModel {
  id: number;
  viewId: string;
  branchId: number;
  customerId: number;
  name: string;
  location: string;
  status: string;
  startDate: number;
  endDate: number;
  totalBudget: number;
  totalExpense: number;
  notes: string;
  createdAt: number;
  updatedAt: number;
  'files[]'?: File[];
}

export const getProjects = async (
  paramObj: object = {},
): Promise<PaginatedProjectModel> => {
  const response = await axios.get<PaginatedProjectModel>(`/projects`, {
    params: paramObj,
  });
  return response.data;
};

export const getProjectById = async (id: string): Promise<ProjectModel> => {
  const response = await axios.get<ProjectModel>(`/projects/${id}`);
  return response.data;
};

export const createProject = async (
  projectData: Omit<ProjectModel, 'id'>,
): Promise<ProjectModel> => {
  const response = await axios.post<ProjectModel>(`/projects`, projectData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateProject = async (
  id: GridRowId,
  projectData: Partial<ProjectModel>,
): Promise<ProjectModel> => {
  const response = await axios.put<ProjectModel>(
    `/projects/${id}`,
    projectData,
  );
  return response.data;
};

export const deleteProject = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/projects/${id}`);
};
