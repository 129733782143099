import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedOperationModel {
  rows: OperationModel[];
  total: number;
}

export interface OperationModel {
  id: number;
  branchId: number;
  employeeId: number;
  inventoryId: number;
  projectId: number;
  customerId: number;
  supplierId: number;
  vehicleId: number;
  operationType: string;
  quantity: number;
  price: number;
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getOperations = async (
  paramObj: object = {},
): Promise<PaginatedOperationModel> => {
  const response = await axios.get<PaginatedOperationModel>(`/operations`, {
    params: paramObj,
  });
  return response.data;
};

export const getOperationById = async (id: number): Promise<OperationModel> => {
  const response = await axios.get<OperationModel>(`/operations/${id}`);
  return response.data;
};

export const createOperation = async (
  operationData: Omit<OperationModel, 'id'>,
): Promise<OperationModel> => {
  const response = await axios.post<OperationModel>(
    `/operations`,
    operationData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const updateOperation = async (
  id: GridRowId,
  operationData: Partial<OperationModel>,
): Promise<OperationModel> => {
  const response = await axios.put<OperationModel>(
    `/operations/${id}`,
    operationData,
  );
  return response.data;
};

export const deleteOperation = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/operations/${id}`);
};
