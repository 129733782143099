import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import _ from 'lodash';

import { LinearProgress } from '@mui/material';

import DrawerLayout from 'layouts/DrawerLayout/DrawerLayout';
import { AuthProvider } from 'contexts/AuthContext';
import PrivateRoute from './PrivateRoute';
import { AvailablePages } from 'constants/AvailablePages';
import { Error, NotFound } from 'pages';
import ProjectDetail from 'pages/Projects/ProjectDetail';

const LoginPage = lazy(() => import('pages/Login/Login'));

const AppRouter = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <ErrorBoundary fallback={<Error />}>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/project/:id"
                element={
                  <PrivateRoute>
                    <ProjectDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/app"
                element={
                  <PrivateRoute>
                    <DrawerLayout />
                  </PrivateRoute>
                }>
                {_.map(
                  _.filter(AvailablePages, { enabled: true }),
                  (item, index) => {
                    return (
                      <Route
                        key={index}
                        path={item.url}
                        element={
                          <PrivateRoute>{<item.component />}</PrivateRoute>
                        }
                      />
                    );
                  },
                )}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
};

export default React.memo(AppRouter);
