import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';

import { Copyright } from 'components/atoms';
import DrawerList from './DrawerList';
import { useLoader } from 'contexts/LoaderContext';
import { useAuth } from 'contexts/AuthContext';

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerLayout = () => {
  const { branch, employee, logout } = useAuth();
  const { showLoader, hideLoader } = useLoader();

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(largeScreen);
  const [appBarHeader, setAppBarHeader] = useState('KAMALAMS');

  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const updateAppBarHeader = (title: string) => {
    setAppBarHeader(title);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: largeScreen ? 3 : 1,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}>
            {appBarHeader}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar>{employee?.name.charAt(0).toUpperCase()}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem sx={{ display: 'block', width: '250px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{ textOverflow: 'ellipsis' }}>
                      {employee?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pt={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ height: '1.6em' }}>
                      {employee?.position}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    pt={1}
                    sx={{ alignContent: 'center', textAlign: 'right' }}>
                    <Typography variant="body2" color="grey">
                      {branch?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  showLoader();
                  logout().finally(() => {
                    hideLoader();
                    navigate('/');
                  });
                }}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" anchor="left" open={open}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
            <MenuOpenIcon />
          </IconButton>

          {/* TODO: Application name to an atom ? or along with Logo with a condition for name */}
          <Typography sx={{ pl: 2 }}>KAMALAMS</Typography>
        </Toolbar>

        <Divider />

        <DrawerList callBackFn={updateAppBarHeader} />
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}>
        <Toolbar />
        <Outlet />
        <Copyright sx={{ pt: 1 }} />
      </Box>
    </Box>
  );
};

export default React.memo(DrawerLayout);
