import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedSupplierModel {
  rows: SupplierModel[];
  total: number;
}

export interface SupplierModel {
  id: number;
  branchId: number;
  name: string;
  phone1: string;
  phone2: string;
  phone3: string;
  address: string;
  gstIn: string;
  balance: number;
  limit: number;
  gst: number;
  iGst: number;
  cGst: number;
  sGst: number;
  notes: string;
  'files[]'?: File[];
  createdAt: number;
  updatedAt: number;
}

export const getSuppliers = async (
  paramObj: object = {},
): Promise<PaginatedSupplierModel> => {
  const response = await axios.get<PaginatedSupplierModel>(`/suppliers`, {
    params: paramObj,
  });
  return response.data;
};

export const getSupplierById = async (id: number): Promise<SupplierModel> => {
  const response = await axios.get<SupplierModel>(`/suppliers/${id}`);
  return response.data;
};

export const createSupplier = async (
  supplierData: Omit<SupplierModel, 'id'>,
): Promise<SupplierModel> => {
  const response = await axios.post<SupplierModel>(`/suppliers`, supplierData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateSupplier = async (
  id: GridRowId,
  supplierData: Partial<SupplierModel>,
): Promise<SupplierModel> => {
  const response = await axios.put<SupplierModel>(
    `/suppliers/${id}`,
    supplierData,
  );
  return response.data;
};

export const deleteSupplier = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/suppliers/${id}`);
};
