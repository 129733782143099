import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Box, Button, FormControl, Grid, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { FormModeType } from './Accounts';
import { useLoader } from 'contexts/LoaderContext';
import {
  AccountModel,
  deletePageFile,
  FileResType,
  getPageFiles,
  updatePageFile,
} from 'apis';
import AlertPopup, { AlertType } from 'components/AlertPopup/AlertPopup';
import { FileUpload } from 'components';

interface AccountFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<AccountModel>;
  callbackFn: (account: Partial<AccountModel>) => void;
  handleCloseFn: () => void;
}

const AccountForm = (props: AccountFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading, showLoader, hideLoader } = useLoader();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [apiFiles, setApiFiles] = useState<FileResType[]>([]);
  const [alertType, setAlertType] = useState<AlertType>('info');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);

  const disabled = props.mode === 'delete';

  if (props.mode === 'edit' || props.mode === 'delete') {
    useEffect(() => {
      showLoader();
      if (formState.id) {
        getPageFiles('accounts', formState.id)
          .then(response => {
            setApiFiles(response);
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error fetching account file(s): ', error);
          })
          .finally(() => hideLoader());
      }
    }, []);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      if (props.mode === 'create') {
        handleLocalProjectFiles(filesArray);
      } else if (props.mode === 'edit') {
        showLoader();
        if (formState.id) {
          updatePageFile('accounts', formState.id, { 'files[]': filesArray })
            .then(response => {
              setAlertType('success');
              setAlertMessage('File(s) uploaded');
              setOpenAlertPopup(true);
              setApiFiles(response);
            })
            .catch(error => {
              setAlertType('error');
              setAlertMessage(error?.message);
              setOpenAlertPopup(true);
              console.error('Error uploading account file(s): ', error);
            })
            .finally(() => hideLoader());
        }
      }
    }
  };

  const handleFileDelete = (fileToDelete: File | FileResType) => {
    if (props.mode === 'create') {
      // Here DELETE happens locally.
      handleLocalProjectFiles(
        _.reject(localFiles, { name: fileToDelete.name }),
      );
    } else if (props.mode === 'edit') {
      // Here DELETE happens via API.
      showLoader();
      if (formState.id) {
        const file = fileToDelete as FileResType;
        deletePageFile('accounts', formState.id, file.signedId)
          .then(() => {
            setAlertType('success');
            setAlertMessage('File deleted');
            setOpenAlertPopup(true);
            setApiFiles(_.reject(apiFiles, { id: file.id }));
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error deleting account file(s): ', error);
          })
          .finally(() => hideLoader());
      }
    }
  };

  const handleLocalProjectFiles = (files: File[]) => {
    setFormState(prevState => ({ ...prevState, 'files[]': files }));
    setLocalFiles(files);
  };

  const handleChangeUpperCase = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<AccountModel>);
  };

  const handleAlertPopupClose = () => {
    setOpenAlertPopup(false);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Code"
              type="text"
              name="code"
              value={formState.code}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 4,
                style: {
                  textTransform: 'uppercase',
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Balance"
              type="number"
              name="balance"
              value={formState.balance}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Minimum Balance"
              type="number"
              name="minimumBalance"
              value={formState.minimumBalance}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="GST"
              type="number"
              name="gst"
              value={formState.gst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="CGST"
              type="number"
              name="cGst"
              value={formState.cGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="SGST"
              type="number"
              name="sGst"
              value={formState.sGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="IGST"
              type="number"
              name="iGst"
              value={formState.iGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Details"
              name="details"
              value={formState.details}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FileUpload
            mode={props.mode}
            disabled={props.mode === 'delete'}
            localFiles={localFiles}
            apiFiles={apiFiles}
            handleFileChangeFn={handleFileChange}
            handleFileDeleteFn={handleFileDelete}
          />
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>

      {alertMessage && (
        <AlertPopup
          type={alertType}
          open={openAlertPopup}
          message={alertMessage}
          onClose={handleAlertPopupClose}
          autoHideDuration={3000}
        />
      )}
    </Box>
  );
};

export default React.memo(AccountForm);
