import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from '@mui/material';

interface ConfirmDialogType {
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDialog = (props: ConfirmDialogType) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <Typography fontWeight={'700'} fontSize={'1.8rem'} pt={2} pl={3}>
          {props.title}
        </Typography>
        <DialogContent>
          <DialogContentText>{props.content}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={props.onConfirm}
            color="error"
            sx={{ px: 3, borderRadius: 2 }}
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ConfirmDialog);
