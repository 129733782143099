import moment from 'moment';
import {
  DateFormatString,
  DateTimeFormatString,
  DayFormatString,
  MonthFormatString,
  TimeFormatString,
  YearFormatString,
} from 'constants/DateTimeFormat';

export const CurrentDateTimeUnix = () => {
  return moment().startOf('minute').unix();
};
export const CurrentDateUnix = () => {
  return moment().startOf('day').unix();
};
export const CurrentMonthUnix = () => {
  return moment().startOf('month').unix();
};
export const PreviousMonthUnix = () => {
  return moment().subtract(1, 'months').startOf('month').unix();
};
export const CurrentYearUnix = () => {
  return moment().startOf('year').unix();
};
export const CurrentDateTimeString = () => {
  return DateTimeFormat(CurrentDateTimeUnix());
};
export const CurrentTimeString = () => {
  return DateFormat(CurrentDateUnix());
};
export const CurrentDateString = () => {
  return DateFormat(CurrentDateUnix());
};
export const CurrentMonthString = () => {
  return DateTimeFormat(CurrentMonthUnix());
};
export const CurrentYearString = () => {
  return DateTimeFormat(CurrentYearUnix());
};
export const DateTimeFormat = (value: number) => {
  return moment.unix(value).format(DateTimeFormatString);
};
export const DateFormat = (value: number) => {
  return moment.unix(value).format(DateFormatString);
};
export const TimeFormat = (value: number) => {
  return moment.unix(value).format(TimeFormatString);
};
export const DayFormat = (value: number) => {
  return moment.unix(value).format(DayFormatString);
};
export const MonthFormat = (value: number) => {
  return moment.unix(value).format(MonthFormatString);
};
export const YearFormat = (value: number) => {
  return moment.unix(value).format(YearFormatString);
};
export const CalendarFormat = (value: number) => {
  return moment.unix(value).calendar();
};
export const ParseMoment = (value: string) => {
  return moment(value);
};
export const ParseEpochDateTime = (value: string) => {
  return ParseMoment(value).startOf('minute').unix();
};
export const ParseEpochDateTimeString = (value: string) => {
  return ParseMoment(value).startOf('minute').unix() as unknown as string;
};
export const ParseEpochDate = (value: string) => {
  return ParseMoment(value).startOf('day').unix();
};
export const ParseEpochDateString = (value: string) => {
  return ParseMoment(value).startOf('day').unix() as unknown as string;
};
