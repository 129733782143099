import axios from 'apis/AxiosConfig';

interface FileReqType {
  'files[]': File[];
}

export interface FileResType {
  id: number;
  signedId: string;
  name: string;
  url: string;
}

// File API endpoint names for pages.
type ApiNameType =
  | 'accounts'
  | 'customers'
  | 'employees'
  | 'inventories'
  | 'operations'
  | 'projects'
  | 'reminders'
  | 'suppliers'
  | 'time_sheets'
  | 'transactions'
  | 'vehicles'
  | 'work_logs';

export const getPageFiles = async (
  apiName: ApiNameType,
  id: number,
): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/${apiName}/${id}/files`);
  return response.data;
};

export const updatePageFile = async (
  apiName: ApiNameType,
  id: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/${apiName}/${id}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const deletePageFile = async (
  apiName: ApiNameType,
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/${apiName}/${id}/files/${signedId}`);
};

/**
 * BELOW FUNCTIONS ARE ABOUT TO BE DEPRECATED IF ABOVE WORKS.
 */

export const updateEmployeeFile = async (
  empId: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/employees/${empId}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getEmployeeFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/employees/${id}/files`);
  return response.data;
};

export const deleteEmployeeFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/employees/${id}/files/${signedId}`);
};

export const updateProjectFile = async (
  empId: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/projects/${empId}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getProjectFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/projects/${id}/files`);
  return response.data;
};

export const deleteProjectFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/projects/${id}/files/${signedId}`);
};

export const updateTransactionFile = async (
  id: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/transactions/${id}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getTransactionFiles = async (
  id: number,
): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/transactions/${id}/files`);
  return response.data;
};

export const deleteTransactionFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/transactions/${id}/files/${signedId}`);
};

export const updateTimeSheetFile = async (
  id: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/time_sheets/${id}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getTimeSheetFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/time_sheets/${id}/files`);
  return response.data;
};

export const deleteTimeSheetFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/time_sheets/${id}/files/${signedId}`);
};

export const updateReminderFile = async (
  id: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/reminders/${id}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getReminderFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/reminders/${id}/files`);
  return response.data;
};

export const deleteReminderFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/reminders/${id}/files/${signedId}`);
};

export const updateAccountFile = async (
  id: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/accounts/${id}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getAccountFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/accounts/${id}/files`);
  return response.data;
};

export const deleteAccountFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/accounts/${id}/files/${signedId}`);
};
