import { Zoom } from '@mui/material';

import { SlideRightTransition } from './motionTransitions';

const components = {
  components: {
    MuiSnackbar: {
      defaultProps: {
        TransitionComponent: SlideRightTransition,
      },
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
        paperFullScreen: {
          borderRadius: 0,
        },
        root: {
          backdropFilter: 'blur(2px)',
        },
      },
    },
  },
};

export default components;
