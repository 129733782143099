import React from 'react';

import { IconButton, IconButtonProps, SlideProps } from '@mui/material';

import { motion } from 'framer-motion';

const SlideRightTransition: React.FC<SlideProps> = props => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.5 }}>
      {props.children}
    </motion.div>
  );
};

// TODO: needs update.
const HoverZoomTransition: React.FC<IconButtonProps> = props => {
  return (
    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
      {/* {props.children} */}
      <IconButton {...props} />
    </motion.div>
  );
};

export { SlideRightTransition, HoverZoomTransition };
