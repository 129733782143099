import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment/moment';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import { FormModeType } from './Employees';
import { useLoader } from 'contexts/LoaderContext';
import {
  EmployeeFormPositionOptions,
  EmployeeFormStatusOptions,
} from 'constants/DropDownOptions';
import { DateFormatString } from 'constants/DateTimeFormat';
import {
  deletePageFile,
  EmployeeModel,
  FileResType,
  getPageFiles,
  updatePageFile,
} from 'apis';
import { AlertPopup, FileUpload } from 'components';
import { AlertType } from 'components/AlertPopup/AlertPopup';
import { AvailablePages } from '../../constants/AvailablePages';

interface EmployeeFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<EmployeeModel>;
  callbackFn: (employee: Partial<EmployeeModel>) => void;
  handleCloseFn: () => void;
}

const EmployeeForm = (props: EmployeeFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading, showLoader, hideLoader } = useLoader();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [apiFiles, setApiFiles] = useState<FileResType[]>([]);
  const [alertType, setAlertType] = useState<AlertType>('info');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);
  // TODO: try to improve this code for rendering.
  // const [displayFiles, setDisplayFiles] = useState<File[] | FileResType[]>([]);

  const disabled = props.mode === 'delete';

  if (props.mode === 'edit' || props.mode === 'delete') {
    useEffect(() => {
      showLoader();
      if (formState.id) {
        getPageFiles('employees', formState.id)
          // .then(response => setDisplayFiles(response as FileResType[]))
          .then(response => {
            setApiFiles(response);
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error fetching employee file(s): ', error);
          })
          .finally(() => hideLoader());
      }
    }, []);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeUpperCase = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (event: SelectChangeEvent<number[]>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const isPermissionCategoryChecked = (
    permissions: number[],
    actions: number[],
  ) => {
    if (_.every(actions, action => permissions.includes(action))) {
      return 'all';
    } else if (_.some(actions, action => permissions.includes(action))) {
      return 'partial';
    }
    return 'none';
  };

  const handlePermissionCategoryCheckboxChange = (page: any) => {
    const currentPermissions = formState.permissions || [];
    const pageActionValues: number[] = Object.values(page.actions);
    const isChecked = isPermissionCategoryChecked(
      currentPermissions,
      pageActionValues,
    );

    if (isChecked === 'all') {
      const newPermissions = currentPermissions.filter(
        permission => !pageActionValues.includes(permission),
      );
      setFormState(prevState => ({
        ...prevState,
        permissions: newPermissions,
      }));
    } else {
      const newPermissions = _.uniq([
        ...currentPermissions,
        ...pageActionValues,
      ]);
      setFormState(prevState => ({
        ...prevState,
        permissions: newPermissions,
      }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      if (props.mode === 'create') {
        handleLocalEmployeeFiles(filesArray);
      } else if (props.mode === 'edit') {
        showLoader();
        if (formState.id) {
          updatePageFile('employees', formState.id, { 'files[]': filesArray })
            // .then(response =>
            //   setDisplayFiles(prevFiles =>
            //     _.concat(prevFiles as FileResType[], response),
            //   ),
            // )
            .then(response => {
              setAlertType('success');
              setAlertMessage('File(s) uploaded');
              setOpenAlertPopup(true);
              setApiFiles(response);
            })
            .catch(error => {
              setAlertType('error');
              setAlertMessage(error?.message);
              setOpenAlertPopup(true);
              console.error('Error uploading employee file(s): ', error);
            })
            .finally(() => hideLoader());
        }
      }
    }
  };

  const handleFileDelete = (fileToDelete: File | FileResType) => {
    if (props.mode === 'create') {
      // Here DELETE happens locally.
      handleLocalEmployeeFiles(
        _.reject(localFiles, { name: fileToDelete.name }),
      );
    } else if (props.mode === 'edit') {
      // Here DELETE happens via API.
      showLoader();
      if (formState.id) {
        const file = fileToDelete as FileResType;
        deletePageFile('employees', formState.id, file.signedId)
          // .then(() =>
          //   setDisplayFiles(
          //     _.reject(apiFiles as FileResType[], { id: file.id }),
          //   ),
          .then(() => {
            setAlertType('success');
            setAlertMessage('File deleted');
            setOpenAlertPopup(true);
            setApiFiles(_.reject(apiFiles, { id: file.id }));
          })
          .catch(error => {
            setAlertType('error');
            setAlertMessage(error?.message);
            setOpenAlertPopup(true);
            console.error('Error deleting employee file(s): ', error);
          })
          .finally(() => hideLoader());
      }
    }
  };

  const handleLocalEmployeeFiles = (files: File[]) => {
    setFormState(prevState => ({ ...prevState, 'files[]': files }));
    // setDisplayFiles(files as File[]);
    setLocalFiles(files);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<EmployeeModel>);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  const handleAlertPopupClose = () => {
    setOpenAlertPopup(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formState.password}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={togglePasswordVisibility}
                      edge="end">
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="status-select-label">
              {disabled ? 'Status' : 'Status *'}
            </InputLabel>
            <Select
              label="Status"
              labelId="status-select-label"
              id="status-select"
              name="status"
              value={formState.status}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(EmployeeFormStatusOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="position-select-label">
              {disabled ? 'Position' : 'Position *'}
            </InputLabel>
            <Select
              label="Position"
              labelId="position-select-label"
              id="position-select"
              name="position"
              value={formState.position}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(EmployeeFormPositionOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 1"
              type="tel"
              name="phone1"
              value={formState.phone1}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 2"
              type="tel"
              name="phone2"
              value={formState.phone2}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 3"
              type="tel"
              name="phone3"
              value={formState.phone3}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Date of Joining"
              name="doj"
              value={moment.unix(formState.doj as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('doj')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Aadhaar"
              type="text"
              name="aadhaar"
              value={formState.aadhaar}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 12,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="License"
              type="text"
              name="license"
              value={formState.license}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 16,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="PAN"
              type="text"
              name="pan"
              value={formState.pan}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Address"
              name="address"
              value={formState.address}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="permissions-select">Permissions</InputLabel>
            <Select
              multiple
              name="permissions"
              value={formState.permissions}
              onChange={handleMultiSelectChange}
              id="permissions-select"
              renderValue={selected => {
                const display: string[] = [];
                _.map(AvailablePages, page => {
                  _.map(Object.keys(page.actions), key => {
                    if (selected.includes(page.actions[key])) {
                      display.push(`${key} ${page.title}`);
                    }
                  });
                });
                return display.join(', ');
              }}
              label="Permissions">
              {_.filter(AvailablePages, page => page.enabled).map(page => [
                <ListSubheader
                  key={page.title}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Typography
                    fontWeight={700}
                    color="primary"
                    sx={{ flexGrow: 1, py: 2 }}>
                    {page.title.toUpperCase()}
                  </Typography>
                  <Checkbox
                    checked={
                      isPermissionCategoryChecked(
                        formState.permissions || [],
                        Object.values(page.actions),
                      ) === 'all'
                    }
                    indeterminate={
                      isPermissionCategoryChecked(
                        formState.permissions || [],
                        Object.values(page.actions),
                      ) === 'partial'
                    }
                    onChange={() =>
                      handlePermissionCategoryCheckboxChange(page)
                    }
                  />
                </ListSubheader>,
                _.map(Object.keys(page.actions), key => [
                  <MenuItem key={page.actions[key]} value={page.actions[key]}>
                    <Checkbox
                      checked={
                        formState.permissions
                          ? formState.permissions.includes(page.actions[key])
                          : false
                      }
                    />
                    {key}
                  </MenuItem>,
                ]),
              ])}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FileUpload
            mode={props.mode}
            disabled={props.mode === 'delete'}
            localFiles={localFiles}
            apiFiles={apiFiles}
            handleFileChangeFn={handleFileChange}
            handleFileDeleteFn={handleFileDelete}
          />
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>

      {alertMessage && (
        <AlertPopup
          type={alertType}
          open={openAlertPopup}
          message={alertMessage}
          onClose={handleAlertPopupClose}
          autoHideDuration={3000}
        />
      )}
    </Box>
  );
};

export default React.memo(EmployeeForm);
